import { useQuery } from '@apollo/client';

import {
  GetCommitteeInvitationsAccessQuery,
  GetCommitteeInvitationsAccessQueryVariables,
} from '@typings/operations';
import { fetchCommitteeInvitationsAccessQuery } from '../queries';

export const useFetchCommitteeInvitationsAccess = () =>
  useQuery<
    GetCommitteeInvitationsAccessQuery,
    GetCommitteeInvitationsAccessQueryVariables
  >(fetchCommitteeInvitationsAccessQuery);
