import React, { useCallback } from 'react';

import { RouteComponentProps } from '@reach/router';

import Link from '@components/Link';

import { useTranslation } from '@external/react-i18next';

import InvitationsList from '../InvitationsList';
import DelegationTooltip from '@presenters/web/components/DelegationToolTip';

import Loading from '@components/Loading';
import { CommitteeNominations } from '@typings/committeeNominations';
import {
  useFetchCommitteeInvitations,
  useFetchCommitteeInvitationsAccess,
} from '@repositories/committeeNominations';
import { OneColumn } from '@components/Layouts/OneColumn';
import AccessDenied from '@components/Pages/AccessDenied';
import Title from '@components/Title';

const DisplayInvitations: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const getUrl = new URL(window.location.href);
  const respondButton = t('invitations.respond-button', 'Respond');

  const noInvitationsAvailableMsg = t(
    'invitations.no-invitations-available-msg',
    'No invitations available.'
  );

  const {
    data: invitationsApp,
    loading: hasAccessLoading,
  } = useFetchCommitteeInvitationsAccess();

  const {
    data: invitationsData,
    loading: isListInvitationsLoading,
  } = useFetchCommitteeInvitations();

  const committeeNominations = invitationsData?.result as CommitteeNominations[];

  const committeeRITRFData = committeeNominations?.filter(
    result => result?.cpo_code !== 'Pres Rep' && result !== null
  );
  const committeePresRepData = committeeNominations?.filter(
    result => result?.cpo_code === 'Pres Rep'
  );

  const generateRespondPageUrl = (invitations: CommitteeNominations) => {
    const navigateRespondPage = `${getUrl.pathname}/respond/${invitations?.nom_key}`;
    return navigateRespondPage;
  };

  const handleRespondInvitations = useCallback(
    (invitations: CommitteeNominations) => {
      const navigateUrl = generateRespondPageUrl(invitations);
      return (
        <Link
          to={navigateUrl}
          className="flex-auto text-xs mb-0 mr-3 desktop-l:ml-3"
        >
          {respondButton}
        </Link>
      );
    },
    []
  );

  if (isListInvitationsLoading || hasAccessLoading) {
    return <Loading />;
  }

  if (!invitationsApp?.access) {
    return (
      <OneColumn className="mb-20">
        <AccessDenied />
      </OneColumn>
    );
  }

  return (
    <>
      <div className="mb-5">
        <Title>{t('invitations.my-invitations.title', 'My Invitations')}</Title>
      </div>
      <div className="text-base mt-6 tablet:mt-6">
        {t(
          'invitations.my-invitations-description',
          `View your invitation(s) to serve on an RI or TRF committee or as the president's representative at a district conference.`
        )}
        <br />
      </div>
      <div className="tablet: mt-4 desktop:mt-4">
        <div className="hidden desktop:block mt-2">
          <DelegationTooltip
            toolTipSummary={t(
              'invitations.committee-ri-trf-title',
              'RI OR TRF COMMITTEE'
            )}
            toolTipContent={t(
              'invitations.committee-ri-trf-desc',
              'Invitations listed for RI or TRF Committee'
            )}
            count={committeeRITRFData?.length.toString()}
          />
          {committeeRITRFData?.length ? (
            <InvitationsList
              committeeNominationsData={committeeRITRFData}
              isPresRepCommittee={false}
              respondActionButton={handleRespondInvitations}
            />
          ) : (
            <div>{noInvitationsAvailableMsg}</div>
          )}
        </div>
        <div className="mt-10">
          <DelegationTooltip
            toolTipSummary={t(
              'invitations.committee-pres-rep-title',
              `President's Representative`
            )}
            toolTipContent={t(
              'invitations.committee-pres-rep-desc',
              'Invitations listed for Presidential Representative Committee'
            )}
            count={committeePresRepData?.length.toString()}
          />
          <div className="hidden desktop:block mt-2">
            {committeePresRepData?.length ? (
              <InvitationsList
                committeeNominationsData={committeePresRepData}
                isPresRepCommittee
                respondActionButton={handleRespondInvitations}
              />
            ) : (
              <div>{noInvitationsAvailableMsg}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayInvitations;
